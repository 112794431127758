/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { toFloat } from '@/helpers/numbers'

const getInitialFormValues = ({ values, initialRows = 3 }) =>
  Array(initialRows)
    .fill(null)
    .map(() => values)

/**
 * @typedef Parameters
 * @property {Object} values
 * @property {number} initialRows
 * @property {string[]} requiredKeys
 */

/**
 * @param {Parameters} parameter
 */
export const useFormListCalculator = ({ values, initialRows, requiredKeys = [], validation }) => {
  const [formValid, setFormValid] = useState(false)
  const [formValues, setFormValues] = useState(
    getInitialFormValues({
      values,
      initialRows
    })
  )

  const invalidFields = useRef(requiredKeys)
  const formValuesKeys = useRef(values)

  useEffect(() => {
    if (
      JSON.stringify(Object.keys(values)) !== JSON.stringify(Object.keys(formValuesKeys.current))
    ) {
      setFormValues(
        getInitialFormValues({
          values,
          initialRows
        })
      )
      formValuesKeys.current = values
    }
  }, [values])

  const isValidInputRequired = newFormValues =>
    newFormValues.some(value =>
      requiredKeys.some(key =>
        typeof value[key] === 'string' ? !!toFloat(value[key]) : !!value[key]
      )
    )

  const getInvalidFields = (property, validInput, fieldsToValidade) => {
    if (!requiredKeys.includes(property)) {
      return fieldsToValidade
    }

    const isPropertyInvalid = fieldsToValidade.includes(property)

    if (isPropertyInvalid) {
      if (validInput) {
        return fieldsToValidade.filter(prop => prop !== property)
      }
      return fieldsToValidade
    }

    if (validInput) {
      return fieldsToValidade
    }

    return [...fieldsToValidade, property]
  }

  const validateValues = values => {
    if (!requiredKeys.length) {
      setFormValid(true)
      return
    }

    let invalidValues = [...requiredKeys]

    values.forEach(row =>
      Object.keys(row).forEach(key => {
        const validInput = validation[key] ? validation[key](row[key]) : true

        invalidValues = getInvalidFields(key, validInput, invalidValues)
      })
    )

    invalidFields.current = invalidValues
    const validInputRequired = isValidInputRequired(formValues)

    setFormValid(!invalidValues.length && validInputRequired)
  }

  useEffect(() => {
    validation && validateValues(formValues)
  }, [formValues, validation])

  const changeValue = (property, value, index) => {
    setFormValues(prevValue => {
      const validInput = validation && validation[property] ? validation[property](value) : true

      const newFormValues = [
        ...prevValue.slice(0, index),
        {
          ...prevValue[index],
          [property]: validInput ? value : ''
        },
        ...prevValue.slice(index + 1)
      ]

      if (requiredKeys.length) {
        const newInvalidField = getInvalidFields(property, validInput, invalidFields.current)

        invalidFields.current = newInvalidField

        const validInputRequired = isValidInputRequired(newFormValues)

        const validForm = !newInvalidField.length && validInputRequired

        setFormValid(validForm)
      }

      return newFormValues
    })
  }

  const resetValues = () => {
    setFormValues(
      getInitialFormValues({
        values,
        initialRows
      })
    )

    setFormValid(false)
    invalidFields.current = requiredKeys
  }

  const addNewValues = () => {
    setFormValues(prevValue => [...prevValue, values])
  }

  return {
    formValues,
    changeValue,
    resetValues,
    addNewValues,
    formValid
  }
}
