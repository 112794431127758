import React from 'react'
import { string, node, func, object } from 'prop-types'

import { Card } from '@/style-guide'

import ResetButton from '../ResetButton'

const CalculatorCard = ({ title, onReset, content, footer, className, style, styleFooter }) => (
  <Card
    className={className}
    style={style}
    header={<Card.Header title={title} action={<ResetButton onClick={onReset} />} />}
    content={content}
    styleFooter={styleFooter}
    footer={footer}
  />
)

CalculatorCard.propTypes = {
  title: string.isRequired,
  onReset: func.isRequired,
  content: node.isRequired,
  footer: node,
  className: string,
  style: object,
  styleFooter: object
}

export default CalculatorCard
