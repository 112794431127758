import React from 'react'
import { node, string, oneOfType } from 'prop-types'
import styled from 'styled-components'

import { Card as CardComponent } from '@/style-guide'
import { black } from '@/style-guide/colors'

const Card = styled(CardComponent)`
  padding: 19px 36px;
  margin-top: 30px;
  min-width: unset;
  max-width: unset;
`

const Section = styled.span`
  font-size: 16px;
  line-height: 29px;
  text-transform: capitalize;
  color: #0050aa;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 36.3969px;
  line-height: 46px;
  margin-top: 20px;
  color: ${black};
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const CardCalculatorInfo = ({ section, title, description }) => (
  <Card
    styleContent={{ padding: '0px', display: 'flex', flexDirection: 'column' }}
    content={
      <>
        <Section>{section}</Section>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </>
    }
  />
)

CardCalculatorInfo.propTypes = {
  section: string,
  title: string,
  description: oneOfType([node, string])
}

export default CardCalculatorInfo
