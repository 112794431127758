import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { array, string, node, oneOfType } from 'prop-types'

import magnifier from '@/assets/icons/magnifier.svg'

import Layout, { TopBar, Footer } from '@/components/Layout'
import SearchBarComponent from '@/components/SearchWidget'

import Header from './Header'
import CalculatorInfoCard from './CalculatorInfoCard'
import CalculatorWidget from './CalculatorWidget'

const Container = styled.div``

const Section = styled.div`
  width: 100%;
  margin-right: 30px;

  ${media.greaterThan('medium')`
   width: 512px;
  `}
`

const Aside = styled.div`
  width: 100%;

  ${media.greaterThan('medium')`
    width: 612px;
    position: relative;
  `}
`

const Content = styled.div`
  padding: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 250px;
  margin-bottom: -250px;
  z-index: 1;
  flex-direction: column;

  ${media.greaterThan('medium')`
    padding: 0px 153px;
    flex-direction: row;
    bottom: 550px;
    margin-bottom: -550px;
  `}

  /* for wide monitor */
  ${media.greaterThan('2200px')`
    padding: 0px 440px;
  `}
`

const SearchBar = styled(SearchBarComponent)`
  display: none;

  ${media.greaterThan('medium')`
    display: flex;
  `}
`

const CalculatorStructure = ({
  asideCards,
  sectionCards,
  section,
  calculatorNode,
  title,
  description,
  headerTitle
}) => (
  <>
    <Layout>
      <Container>
        <TopBar />
        <Header section={section} headerTitle={headerTitle} />
        <Content>
          <Section>
            {calculatorNode}
            {sectionCards}
          </Section>
          <Aside>
            <SearchBar
              styleButton={{ width: '69px', height: '72px' }}
              iconButton={<img src={magnifier} alt="Search" />}
              simple
            />
            <CalculatorInfoCard section={section} title={title} description={description} />
            {asideCards}
          </Aside>
        </Content>
        <CalculatorWidget calculator={calculatorNode} title={title} />
        <Footer />
      </Container>
    </Layout>
  </>
)

CalculatorStructure.propTypes = {
  asideCards: oneOfType([node, array]),
  sectionCards: oneOfType([node, array]),
  section: string,
  title: string,
  headerTitle: string,
  description: oneOfType([node, string]),
  calculatorNode: node
}

export default CalculatorStructure
