import React, { useState } from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black, white } from '@/style-guide/colors'

import ModalCard from './ModalCard'

const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-top: 50px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
  margin-top: 40px;

  ${media.greaterThan('medium')`
    padding: 0px 153px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `}

    /* for wide monitor */
    ${media.greaterThan('2200px')`
      padding: 0px 440px;
    `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  width: 100%;
  height: 56px;

  > span {
    font-weight: bold;
    font-size: 16px;
    line-height: 46px;

    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  background: #4573de;
`

const Description = styled.div`
  color: ${black};

  ${media.greaterThan('medium')`
    width: 398px;
  `}
`

const WidgetContent = styled.div`
  position: relative;

  ${media.greaterThan('medium')`
    width: 430px;
  `}
`

const Mask = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`

const MaskCalculator = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`

const Widget = ({ calculator, title }) => {
  const [visible, setVisible] = useState(false)

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <Container>
      <Header>
        <span>GET THE WIDGET!</span>
      </Header>
      <Content>
        <Description>
          <p>
            Boiling Point Calculator can be embedded on your website to enrich the content you wrote
            and make it easier for your visitors to understand your message.
          </p>
          <p>It is free, awesome and will keep people coming back!</p>
        </Description>
        <WidgetContent
          onClick={e => {
            e.stopPropagation()

            handleToggleVisible()
          }}
        >
          {calculator}
          <MaskCalculator />
        </WidgetContent>
      </Content>
      {visible && (
        <>
          <Mask onClick={handleToggleVisible}>
            <ModalCard calculator={calculator} title={title} />
          </Mask>
        </>
      )}
    </Container>
  )
}

Widget.propTypes = {
  calculator: node,
  title: string
}

export default Widget
