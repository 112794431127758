/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'

import { toFloat, isValidNumber } from '@/helpers/numbers'

/**
 * @typedef Parameters
 * @property {Object} values
 * @property {string[]} requiredKeys
 */

/**
 * @param {Parameters} parameter
 */
export const useFormCalculator = ({ values, requiredKeys = [], validation = {} }) => {
  const hasValidation = !!requiredKeys.length
  const [formValid, setFormValid] = useState(!hasValidation)
  const [formValues, setFormValues] = useState(values)

  const invalidFields = useRef(requiredKeys)

  const isValidInputRequired = newFormValues =>
    requiredKeys.every(key =>
      typeof newFormValues[key] === 'string'
        ? isValidNumber(toFloat(newFormValues[key]))
        : !!newFormValues[key]
    )

  const getInvalidFields = (property, validInput, fieldsToValidade) => {
    if (!requiredKeys.includes(property)) {
      return fieldsToValidade
    }

    const isPropertyInvalid = fieldsToValidade.includes(property)

    if (isPropertyInvalid) {
      if (validInput) {
        return fieldsToValidade.filter(prop => prop !== property)
      }
      return fieldsToValidade
    }

    if (validInput) {
      return fieldsToValidade
    }

    return [...fieldsToValidade, property]
  }

  const validateValues = values => {
    let invalidValues = [...requiredKeys]
    Object.keys(values).forEach(key => {
      const validInput = validation[key] ? validation[key](values[key]) : true

      invalidValues = getInvalidFields(key, validInput, invalidValues)
    })

    invalidFields.current = invalidValues

    const validInputRequired = isValidInputRequired(invalidValues)

    setFormValid(!invalidValues.length && validInputRequired)
  }

  useEffect(() => {
    if (JSON.stringify(Object.keys(values)) !== JSON.stringify(Object.keys(formValues))) {
      setFormValues(values)
      validateValues(values)
    }
  }, [formValues, values])

  const changeValue = (property, value) => {
    setFormValues(prevValue => {
      const validInput = validation[property] ? validation[property](value) : true

      const newFormValues = {
        ...prevValue,
        [property]: validInput ? value : ''
      }

      const newInvalidField = getInvalidFields(property, validInput, invalidFields.current)

      invalidFields.current = newInvalidField

      const validInputRequired = isValidInputRequired(newFormValues)

      setFormValid(!newInvalidField.length && validInputRequired)

      return newFormValues
    })
  }

  const resetValues = () => {
    setFormValues(values)
    setFormValid(!hasValidation)
    invalidFields.current = requiredKeys
  }

  return {
    formValues,
    changeValue,
    resetValues,
    formValid
  }
}
