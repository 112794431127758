import React, { memo } from 'react'
import { array, number, string } from 'prop-types'
import { Doughnut } from 'react-chartjs-2'

import { lightBlue, lightBlueOpaque, semiDarkBlue, semiDarkBlueOpaque } from '@/style-guide/colors'

const DoughnutChart = ({
  data,
  width,
  height = 250,
  middleValue,
  backgroundColor = [lightBlueOpaque, semiDarkBlueOpaque],
  hoverBackgroundColor = [lightBlue, semiDarkBlue],
  labels
}) => (
  <Doughnut
    width={width}
    height={height}
    redraw
    options={{ maintainAspectRatio: false }}
    data={{
      datasets: [
        {
          data,
          backgroundColor,
          hoverBackgroundColor
        }
      ],
      labels
    }}
    plugins={[
      {
        beforeDraw: chart => {
          const {
            chart: { width, height, ctx },
            legend: { height: legendHeight }
          } = chart

          ctx.restore()
          const fontSize = (height / 180).toFixed(2)
          ctx.font = `${fontSize}em sans-serif`
          ctx.textBaseline = 'middle'

          const textX = Math.round((width - ctx.measureText(middleValue).width) / 2)
          const textY = (height + legendHeight) / 2

          ctx.fillText(middleValue, textX, textY)
          ctx.save()
        }
      }
    ]}
  />
)

DoughnutChart.propTypes = {
  data: array.isRequired,
  width: number,
  height: number,
  middleValue: string,
  backgroundColor: array,
  hoverBackgroundColor: array,
  labels: array
}

export default memo(
  DoughnutChart,
  (prevProps, nextProps) => JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
)
