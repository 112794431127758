import { percentageToLetterGrade, gpaToLetterGrade } from '@/helpers/calculators'

export const TABS = {
  PERCENTAGE: 0,
  LETTER: 1,
  GPA: 2
}

const getGradeLetter = (finalExamGrade, activeTab) => {
  if (activeTab === TABS.PERCENTAGE) return percentageToLetterGrade(finalExamGrade)

  return gpaToLetterGrade(finalExamGrade)
}

/**
 * @typedef {Object} Values
 * @property {number} requiredGrade
 * @property {number} weight
 * @property {number} currentGrade
 *
 */

/**
 * Final Grade Calculation
 *
 * Final exam grade = ( requiredGrade - (100% - weight) × currentGrade ) / weight
 *
 * @param {Values} values
 * @param {number} activeTab
 */
export const calculateFinalGrade = ({ requiredGrade, weight, currentGrade }, activeTab) => {
  const requiredGradeValue = typeof requiredGrade === 'object' ? requiredGrade.value : requiredGrade
  const currentGradeValue = typeof currentGrade === 'object' ? currentGrade.value : currentGrade

  const finalExamGrade = (100 * requiredGradeValue - (100 - weight) * currentGradeValue) / weight
  const gradeLetter = getGradeLetter(finalExamGrade, activeTab)

  return [finalExamGrade, gradeLetter]
}
