import React from 'react'
import styled from 'styled-components'

import { Card as CardComponent } from '@/style-guide'

const Card = styled(CardComponent)`
  padding: 40px 36px;
  margin-top: 30px;
  min-width: unset;
  max-width: unset;
`

const Title = styled.span`
  font-weight: normal;
  font-size: 21px;
  line-height: 23px;
  color: #092c4c;
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const SectionInfoCard = () => (
  <Card
    styleContent={{ padding: '0px', display: 'flex', flexDirection: 'column' }}
    content={
      <>
        <Title>What is your final grade?</Title>
        <Description>
          <p>
            Before we get into the nitty gritty of how to use this final grade calculator or the
            justification for using it, it is worth making sure we are all on the same page and know
            what we mean by final grade. The final grade of a subject or of a semester is the score
            of all of your relevant grades and their average according to their individual weights.
            This might seem complicated but it&apos;s easier than it looks.
          </p>
          <p>
            For many subjects, the final grade is the grade you would get in the final (and only)
            exam. If this is your case, close the browser and get to studying - you&apos;re not
            trying to calculate anything, you&apos;re procrastinating. In many other cases, one
            subject might have multiple tests that can range from smaller mid-term exams to written
            coursework and essays to oral presentations. In those cases, you need to know the weight
            of each of the forms of examination.
          </p>
          <p>
            The weight of each of these exams is no more than a mathematical representation of their
            importance. Generally, the weights are expressed as a percentage of the total grade or
            as a number from 0 to 1, which represents the same concept.
          </p>
          <p>
            There is also the final semester grade, which you can obtain using this calculator. The
            final semester grade is the combination of all the grades of all the subjects you took
            in a given semester. Generally, these grades tend to have the same weight, but sometimes
            different subjects have different credit counts or a different impact on your final
            degree. In the latter case, the weighting system works the same as in the case of an
            individual subject with multiple examination processes.
          </p>
          <p>
            There are multiple grading systems with different values and nomenclatures, so we have
            to settle for one as the default for any future example we will make. For simplicity,
            our exemplary grading system will range from 0 to 100, with a passing grade to be
            determined by the user. We will explain this in more detail when we talk about how to
            use this calculator.
          </p>
        </Description>
      </>
    }
  />
)

export default SectionInfoCard
