import React, { useState } from 'react'

import { Button, Input, Grid } from '@/style-guide'

import { toFloat } from '@/helpers/numbers'
import { GPAS } from '@/helpers/calculators'
import { number } from '@/helpers/validations'
import { useFormListCalculator } from '@/hooks/useFormListCalculator'

import { LetterGradeSelector } from '../components'
import { calculateGPA } from './calculations'
import { CalculatorCard, ResultCard, DoughnutChart } from '../../components'

const INITIAL_ROWS = 6
const MAX_GPA = 4.33
const INITIAL_CHART_VALUE = [MAX_GPA]

const Gpa = () => {
  const [resultGpa, setResultGpa] = useState()
  const [sumCreditsHours, setSumCreditsHours] = useState()
  const [chartData, setChartData] = useState(INITIAL_CHART_VALUE)

  const { formValid, formValues, changeValue, resetValues, addNewValues } = useFormListCalculator({
    values: {
      class: '',
      grade: GPAS[0],
      credit: ''
    },
    initialRows: INITIAL_ROWS,
    validation: {
      credit: number.positiveNumber
    }
  })

  const handleReset = () => {
    resetValues()
    setResultGpa(null)
    setSumCreditsHours(null)
  }

  const handleCalculate = () => {
    const [resultGpa, sumCreditsHours] = calculateGPA(formValues)

    const valueGpaToChart = toFloat(resultGpa)
    const leftGpa = toFloat((MAX_GPA - valueGpaToChart).toFixed(2))

    setChartData([leftGpa, valueGpaToChart])
    setResultGpa(resultGpa)
    setSumCreditsHours(sumCreditsHours)
  }

  const renderRows = () =>
    formValues.map((row, indexRow) => (
      <Grid.Row key={indexRow}>
        <Grid.Col>
          <Input
            label="Class"
            value={row.class}
            placeholder={`Class ${indexRow + 1}`}
            onChange={value => changeValue('class', value, indexRow)}
          />
        </Grid.Col>
        <Grid.Col>
          <LetterGradeSelector
            label="Grade"
            value={row.grade}
            onChange={value => changeValue('grade', value, indexRow)}
          />
        </Grid.Col>
        <Grid.Col>
          <Input
            label="Credit/Hours"
            value={row.credit}
            type="number"
            step={0.25}
            onChange={value => changeValue('credit', value, indexRow)}
          />
        </Grid.Col>
      </Grid.Row>
    ))

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col>
          <Input label="GPA" disabled value={resultGpa} />
        </Grid.Col>
        <Grid.Col>
          <Input label="Total Credits" disabled value={sumCreditsHours} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col style={{ alignItems: 'center' }}>
          <DoughnutChart data={chartData} labels={['Left GPA', 'GPA']} middleValue={resultGpa} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  return (
    <>
      <CalculatorCard
        title="GPA Calculator"
        onReset={handleReset}
        content={
          <>
            <Grid>{renderRows()}</Grid>
          </>
        }
        footer={
          <Grid.Row>
            <Grid.Col style={{ alignItems: 'center' }}>
              <Button type="default" key="btn-addRow" label="Add row" onClick={addNewValues} />
            </Grid.Col>

            <Grid.Col style={{ alignItems: 'center' }}>
              <Button
                disabled={!formValid}
                key="btn-calculate"
                label="Calculate"
                onClick={handleCalculate}
              />
            </Grid.Col>
          </Grid.Row>
        }
      />
      {!!resultGpa && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default Gpa
