import { toFloat } from '@/helpers/numbers'

/**
 * @typedef {Object} Row
 * @property {string} className
 * @property {Object} grade
 * @property {string} credit
 * @property {Object} weight
 */

/**
 *
 * GPA = ((g1 * w1) + (g2 * w2) + (etc)) / sum of credits/hours
 * @param {Row[]} grades
 */
export const calculateHighSchoolCalculator = grades => {
  let x = ''
  let y = ''

  const values = grades.map(({ grade, credit, weight }) => {
    const gradePlusWeight = toFloat(grade.value) + weight.value
    const creditValue = toFloat(credit)

    const value = {
      grade: gradePlusWeight * creditValue,
      credit: creditValue
    }

    x += creditValue ? `${creditValue}x${gradePlusWeight}+` : ''
    y += creditValue ? `${creditValue}+` : ''

    return value
  })

  const sumGrades = values.reduce((prev, curr) => {
    return prev + curr.grade
  }, 0)

  const sumCreditsHours = values.reduce((prev, curr) => {
    return prev + curr.credit
  }, 0)

  const gpaValue = sumGrades / sumCreditsHours
  const GPA = gpaValue ? gpaValue.toFixed(2) : 0

  const removePlusRgx = /\+$/g
  const calculation = `(${x.replace(removePlusRgx, '')}) / (${y.replace(
    removePlusRgx,
    ''
  )}) = ${GPA}`

  return [GPA, sumCreditsHours, calculation]
}
