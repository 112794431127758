import React, { useState } from 'react'
import { Button, Grid, Input, Tab } from '@/style-guide'

import { percentageToLetterGrade, gpaToLetterGrade, GPAS } from '@/helpers/calculators'
import { number, object } from '@/helpers/validations'
import { useFormListCalculator } from '@/hooks/useFormListCalculator'
import { toFloat } from '@/helpers/numbers'

import { calculateGrade, TABS } from './calculations'
import { CalculatorCard, DoughnutChart, ResultCard } from '../../components'
import { LetterGradeSelector } from '../components'

const INITIAL_ROWS = 6
const MAX_PERCENTAGE = 100
const MAX_GPA = 4.33
const INITIAL_CHART_VALUE = [MAX_PERCENTAGE]

const Grade = () => {
  const [resultGpa, setResultGpa] = useState()
  const [chartData, setChartData] = useState(INITIAL_CHART_VALUE)
  const [activeTab, setActiveTab] = useState(TABS.PERCENTAGE)
  const [grade, setGrade] = useState('')

  const isTabLetterSelected = activeTab === TABS.LETTER

  const { formValid, formValues, changeValue, resetValues, addNewValues } = useFormListCalculator({
    values: {
      [isTabLetterSelected ? 'gradeLt' : 'grade']: grade,
      weight: ''
    },
    initialRows: INITIAL_ROWS,
    validation: {
      grade: number.positiveNumber,
      gradeLt: object.validObject
    }
  })

  const handleReset = () => {
    resetValues()
    setResultGpa('')
  }

  const handleTabChange = tabIndex => {
    setGrade(tabIndex === TABS.LETTER ? GPAS[0] : '')
    setActiveTab(tabIndex)
    resetValues()
  }

  const handleCalculate = () => {
    const avgGrade = calculateGrade(formValues)

    const valueGpaToChart = toFloat(avgGrade)
    const maxValue = isTabLetterSelected ? MAX_GPA : MAX_PERCENTAGE
    const leftGpa = toFloat((maxValue - valueGpaToChart).toFixed(2))

    setChartData([leftGpa, valueGpaToChart])
    setResultGpa(avgGrade)
  }

  const renderRowsPercentage = () =>
    formValues.map((row, indexRow) => (
      <Grid.Row key={indexRow}>
        <Grid.Col>
          <Input
            label="Grade"
            value={row.grade}
            type="number"
            onChange={value => changeValue('grade', value, indexRow)}
          />
        </Grid.Col>
        <Grid.Col>
          <Input
            label="Weight(%)"
            value={row.weight}
            type="number"
            step={0.25}
            onChange={value => changeValue('weight', value, indexRow)}
          />
        </Grid.Col>
      </Grid.Row>
    ))

  const renderRowsLetters = () =>
    formValues.map((row, indexRow) => (
      <Grid.Row key={indexRow}>
        <Grid.Col>
          <LetterGradeSelector
            label="Grade"
            value={row.gradeLt}
            onChange={value => changeValue('gradeLt', value, indexRow)}
          />
        </Grid.Col>
        <Grid.Col>
          <Input
            label="Credits"
            value={row.weight}
            type="number"
            step={0.25}
            onChange={value => changeValue('weight', value, indexRow)}
          />
        </Grid.Col>
      </Grid.Row>
    ))

  const renderRowsPoints = () =>
    formValues.map((row, indexRow) => (
      <Grid.Row key={indexRow}>
        <Grid.Col>
          <Input
            label="Grade"
            value={row.grade}
            type="number"
            onChange={value => changeValue('grade', value, indexRow)}
          />
        </Grid.Col>
        <Grid.Col>
          <Input
            label="Max Grade"
            value={row.weight}
            type="number"
            step={0.25}
            onChange={value => changeValue('weight', value, indexRow)}
          />
        </Grid.Col>
      </Grid.Row>
    ))

  const panes = [
    {
      menuItem: 'Percentage',
      render: () => <Grid>{renderRowsPercentage()}</Grid>
    },
    {
      menuItem: 'Letters',
      render: () => <Grid>{renderRowsLetters()}</Grid>
    },
    {
      menuItem: 'Points',
      render: () => <Grid>{renderRowsPoints()}</Grid>
    }
  ]

  const renderResult = () => {
    const gpa = isTabLetterSelected
      ? gpaToLetterGrade(resultGpa)
      : percentageToLetterGrade(resultGpa)

    const middleValue = isTabLetterSelected ? resultGpa : `${resultGpa}%`

    return (
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <Input label="Average grade" disabled value={resultGpa} />
          </Grid.Col>
          <Grid.Col>
            <Input disabled value={gpa} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col style={{ alignItems: 'center' }}>
            <DoughnutChart
              data={chartData}
              labels={['Left grade', 'Average grade']}
              middleValue={middleValue}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <>
      <CalculatorCard
        title="Grade Calculator"
        onReset={handleReset}
        content={<Tab panes={panes} onTabChange={handleTabChange} />}
        footer={
          <Grid.Row>
            <Grid.Col style={{ alignItems: 'center' }}>
              <Button type="default" key="btn-addRow" label="Add row" onClick={addNewValues} />
            </Grid.Col>

            <Grid.Col style={{ alignItems: 'center' }}>
              <Button
                disabled={!formValid}
                key="btn-calculate"
                label="Calculate"
                onClick={handleCalculate}
              />
            </Grid.Col>
          </Grid.Row>
        }
      />
      {!!resultGpa && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default Grade
