import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Card as CardComponent, Button as ButtonComponent } from '@/style-guide'

import copy from '@/assets/icons/copy.svg'

const Button = styled(ButtonComponent)`
  max-width: unset;
  min-height: unset;
  height: 39px;
`

const Card = styled(CardComponent)`
  width: 90%;
  height: fit-content !important;
  max-width: unset;
  min-width: unset;
  margin: auto;
  border: unset;

  ${media.greaterThan('medium')`
    width: 1000px;
  `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  max-height: 539px;
  overflow-x: auto;

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`

const Section = styled.div`
  position: relative;
  display: none;

  ${media.greaterThan('medium')`
    display: block;
    width: 430px;
  `}
`

const Aside = styled.div`
  height: fit-content;
  background: #ebf7ff;

  ${media.greaterThan('medium')`
    width: 457px;
  `}
`

const CodeContent = styled.div`
  padding: 20px;
`

const Code = styled.pre`
  font-size: 12px;
  line-height: 22px;

  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`

const Img = styled.img`
  margin-right: 10px;
`

const Label = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2em;
`

const MaskCalculator = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`

const ModalCard = ({ calculator, title }) => (
  <Card
    header={
      <Card.Header
        title={title}
        style={{
          background: 'linear-gradient(17.45deg, #4371DD 31.79%, #92E7F2 97.12%)',
          color: '#fff',
          height: '89px'
        }}
      />
    }
    content={
      <Container>
        <Section>
          {calculator}
          <MaskCalculator />
        </Section>
        <Aside>
          <CodeContent>
            <Code>
              {`<div class="omni-calculator" data-calculator="food/donut" data-width="300" data-config="{}" data-currency="IDR" data-show-row-controls="false" data-version="3" data-t="1582545544827"> \n  <div className="omni-calculator-header"> \n    Do or Donut Calculator \n  </div> \n  <div class="omni-calculator-footer">
              `}
            </Code>
          </CodeContent>
          <Button label={<Label>Copy code now</Label>} icon={<Img src={copy} alt="Copy" />} />
        </Aside>
      </Container>
    }
  />
)

ModalCard.propTypes = {
  calculator: node,
  title: string
}

export default ModalCard
