import React, { Fragment, useState } from 'react'
import { string, object } from 'prop-types'

import { Card, Grid, Input, Dropdown, Separator, Button, Icon, Formula } from '@/style-guide'

import { GPAS } from '@/helpers/calculators'
import { toFloat } from '@/helpers/numbers'
import { useFormListCalculator } from '@/hooks/useFormListCalculator'

import { semiDarkBlue } from '@/style-guide/colors'
import { CalculatorCard, ResultCard, DoughnutChart } from '../../components'

import { calculateHighSchoolCalculator } from './calculations'

const WEIGHTS = [
  {
    key: 'Regular',
    value: 0
  },
  {
    key: 'Honors',
    value: 0.5
  },
  {
    key: 'AP',
    value: 1
  },
  {
    key: 'IB',
    value: 1
  },
  {
    key: 'College',
    value: 1
  }
]

const MAX_GPA = 4.33
const INITIAL_CHART_VALUE = [MAX_GPA]

const HighSchool = ({ className, style }) => {
  const [gpaValue, setGpaValue] = useState('')
  const [sumCreditsHours, setSumCreditsHours] = useState('')
  const [calculation, setCalculation] = useState('')
  const [chartData, setChartData] = useState(INITIAL_CHART_VALUE)

  const { formValid, formValues, changeValue, addNewValues, resetValues } = useFormListCalculator({
    values: {
      className: '',
      grade: GPAS[0],
      credit: '',
      weight: WEIGHTS[0]
    },
    initialRows: 3,
    requiredKeys: ['credit']
  })

  const handleCalculate = () => {
    const [resultGpa, sumCreditsHours, calculationValue] = calculateHighSchoolCalculator(formValues)

    setGpaValue(resultGpa)
    setSumCreditsHours(sumCreditsHours)
    setCalculation(calculationValue)

    const valueGpaToChart = toFloat(resultGpa)
    const leftGpa = toFloat((MAX_GPA - valueGpaToChart).toFixed(2))
    setChartData([leftGpa, valueGpaToChart])
  }

  const handleResetValues = () => {
    resetValues()
    setGpaValue('')
    setSumCreditsHours('')
    setCalculation('')
    setChartData(INITIAL_CHART_VALUE)
  }

  const renderRows = () =>
    formValues.map(({ className, grade, credit, weight }, indexRow) => (
      <Fragment key={indexRow}>
        <Separator>Exam {indexRow + 1}</Separator>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                value={className}
                label="Class Name / Grade"
                onChange={value => changeValue('className', value, indexRow)}
                action={
                  <Dropdown
                    width={80}
                    options={GPAS}
                    value={grade}
                    onChange={value => changeValue('grade', value, indexRow)}
                  />
                }
              />
            </Grid.Col>
            <Grid.Col>
              <Input
                value={credit}
                label="Credits / Weight"
                onChange={value => changeValue('credit', value, indexRow)}
                action={
                  <Dropdown
                    options={WEIGHTS}
                    value={weight}
                    onChange={value => changeValue('weight', value, indexRow)}
                  />
                }
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Fragment>
    ))

  const renderGradeCalculation = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col>
          <Input label="GPA" disabled value={gpaValue} />
        </Grid.Col>
        <Grid.Col>
          <Input label="Total Credits" disabled value={sumCreditsHours} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Formula>{calculation}</Formula>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col style={{ alignItems: 'center' }}>
          <DoughnutChart data={chartData} labels={['Left GPA', 'GPA']} middleValue={gpaValue} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  return (
    <>
      <CalculatorCard
        className={className}
        style={style}
        title="High School GPA Calculator"
        onReset={handleResetValues}
        content={renderRows()}
        footer={
          <Grid.Row style={{ justifyContent: 'space-between' }}>
            <Grid.Col style={{ alignItems: 'center' }}>
              <Button
                type="default"
                label="Add Row"
                icon={
                  <Icon name="other" size={16} color={semiDarkBlue} style={{ marginRight: 4 }} />
                }
                onClick={addNewValues}
              />
            </Grid.Col>

            <Grid.Col style={{ alignItems: 'center' }}>
              <Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />
            </Grid.Col>
          </Grid.Row>
        }
      />

      {gpaValue && (
        <>
          <Card
            style={{ marginTop: 25 }}
            header={<Card.Header light title="Grade Calculation" />}
            content={renderGradeCalculation()}
          />

          <ResultCard style={{ marginTop: 25 }} content={renderResult()} />
        </>
      )}
    </>
  )
}

HighSchool.propTypes = {
  className: string,
  style: object
}

export default HighSchool
