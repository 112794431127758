import React, { useState } from 'react'

import { Button, Tab, Grid, Input } from '@/style-guide'
import { useFormCalculator } from '@/hooks/useFormCalculator'
import { number, object } from '@/helpers/validations'
import { GPAS } from '@/helpers/calculators'

import { LetterGradeSelector } from '../components'
import { calculateFinalGrade, TABS } from './calculations'
import { CalculatorCard, ResultCard } from '../../components'

const gradeValues = {
  currentGrade: '',
  requiredGrade: ''
}

const gradeValuesLetter = {
  currentGradeLt: GPAS[0],
  requiredGradeLt: GPAS[0]
}

const FinalGrade = () => {
  const [finalExamGrade, setFinalExamGrade] = useState()
  const [finalGradeLetter, setFinalGradeLetter] = useState()
  const [currentValues, setCurrentValues] = useState(gradeValues)
  const [activeTab, setActiveTab] = useState(TABS.PERCENTAGE)

  const isTabLetterSelected = activeTab === TABS.LETTER

  const { formValues, formValid, resetValues, changeValue } = useFormCalculator({
    values: {
      ...currentValues,
      weight: ''
    },
    requiredKeys: isTabLetterSelected
      ? ['currentGradeLt', 'requiredGradeLt', 'weight']
      : ['currentGrade', 'requiredGrade', 'weight'],
    validation: {
      currentGrade: number.positiveNumber,
      requiredGrade: number.positiveNumber,
      currentGradeLt: object.validObject,
      requiredGradeLt: object.validObject,
      weight: number.positiveNumber
    }
  })

  const handleReset = () => {
    setFinalExamGrade()
    setFinalGradeLetter()
    resetValues()
  }

  const handleTabChange = tabIndex => {
    setActiveTab(tabIndex)
    setCurrentValues(tabIndex === TABS.LETTER ? gradeValuesLetter : gradeValues)
    resetValues()
  }

  const handleCalculate = () => {
    const [examGrade, gradeLetter] = calculateFinalGrade(formValues, activeTab)
    setFinalExamGrade(examGrade)
    setFinalGradeLetter(gradeLetter)
  }

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col>Final exam grade needed</Grid.Col>
      </Grid.Row>

      {isTabLetterSelected ? (
        <Grid.Row>
          <Grid.Col>
            <Input disabled value={finalGradeLetter} />
          </Grid.Col>
          <Grid.Col>
            <Input disabled value={finalExamGrade} />
          </Grid.Col>
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Col>
            <Input disabled value={finalExamGrade} />
          </Grid.Col>
          <Grid.Col>
            <Input disabled value={finalGradeLetter} />
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid>
  )

  const panes = [
    {
      menuItem: 'Percentage',
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Current grade"
                value={formValues.currentGrade}
                type="number"
                onChange={value => changeValue('currentGrade', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Target class grade"
                type="number"
                value={formValues.requiredGrade}
                onChange={value => changeValue('requiredGrade', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Final exam weight"
                type="number"
                value={formValues.weight}
                onChange={value => changeValue('weight', value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )
    },
    {
      menuItem: 'Letter',
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <LetterGradeSelector
                label="Current grade"
                value={formValues.currentGradeLt}
                onChange={value => changeValue('currentGradeLt', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <LetterGradeSelector
                label="Target class grade"
                value={formValues.requiredGradeLt}
                onChange={value => changeValue('requiredGradeLt', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Final exam weight"
                type="number"
                value={formValues.weight}
                onChange={value => changeValue('weight', value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )
    },
    {
      menuItem: 'GPA',
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Current GPA"
                type="number"
                value={formValues.currentGrade}
                onChange={value => changeValue('currentGrade', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Target GPA"
                type="number"
                value={formValues.requiredGrade}
                onChange={value => changeValue('requiredGrade', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Final exam weight"
                type="number"
                value={formValues.weight}
                onChange={value => changeValue('weight', value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )
    }
  ]

  return (
    <>
      <CalculatorCard
        title="Final Grade Calculator"
        onReset={handleReset}
        content={<Tab panes={panes} onTabChange={handleTabChange} />}
        footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
      />

      {!!finalExamGrade && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default FinalGrade
