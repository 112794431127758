import React, { useState } from 'react'

import { Button, Grid, Input } from '@/style-guide'

import { toFloat } from '@/helpers/numbers'
import { number } from '@/helpers/validations'
import { gpaToLetterGrade } from '@/helpers/calculators'
import { useFormCalculator } from '@/hooks/useFormCalculator'

import { CalculatorCard, ResultCard } from '../../components'

const GpaToLetter = () => {
  const [result, setResult] = useState('')

  const { formValues, formValid, resetValues, changeValue } = useFormCalculator({
    values: {
      gpa: ''
    },
    requiredKeys: ['gpa'],
    validation: {
      gpa: number.positiveNumber
    }
  })

  const getValue = () => {
    return gpaToLetterGrade(toFloat(formValues.gpa))
  }

  const handleCalculate = () => setResult(getValue())

  const handleReset = () => {
    resetValues()
    setResult('')
  }

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col style={{ alignItems: 'center' }}>
          <Input label="GPA" disabled value={result} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  return (
    <>
      <CalculatorCard
        title="GPA To Letter"
        onReset={handleReset}
        content={
          <Grid>
            <Grid.Row>
              <Grid.Col>
                <Input
                  label="GPA"
                  type="number"
                  value={formValues.gpa}
                  onChange={value => changeValue('gpa', value)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        }
        footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
      />

      {!!result && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default GpaToLetter
