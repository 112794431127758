export const GPAS = [
  {
    key: 'A+',
    value: 4.33,
    percentage: 97
  },
  {
    key: 'A',
    value: 4,
    percentage: 93
  },
  {
    key: 'A-',
    value: 3.67,
    percentage: 90
  },
  {
    key: 'B+',
    value: 3.33,
    percentage: 87
  },
  {
    key: 'B',
    value: 3,
    percentage: 83
  },
  {
    key: 'B-',
    value: 2.67,
    percentage: 80
  },
  {
    key: 'C+',
    value: 2.33,
    percentage: 77
  },
  {
    key: 'C',
    value: 2,
    percentage: 73
  },
  {
    key: 'C-',
    value: 1.67,
    percentage: 70
  },
  {
    key: 'D+',
    value: 1.33,
    percentage: 67
  },
  {
    key: 'D',
    value: 1,
    percentage: 63
  },
  {
    key: 'D-',
    value: 0.67,
    percentage: 60
  },
  {
    key: 'F',
    value: 0,
    percentage: 0
  }
]

export const percentageToLetterGrade = percentageValue => {
  const gpaValue = GPAS.find(({ percentage }) => percentageValue > percentage)
  return gpaValue ? gpaValue.key : 'F'
}

export const gpaToLetterGrade = gpa => {
  const gpaValue = GPAS.find(({ value }) => gpa >= value)
  return gpaValue ? gpaValue.key : 'F'
}

export const letterToGpa = letter => {
  const result = GPAS.find(g => g.key === letter)
  return result ? result.value : '-'
}

export const getGradeLetters = () => [
  'A+',
  'A',
  'A-',
  'B+',
  'B',
  'B-',
  'C+',
  'C',
  'C-',
  'D+',
  'D',
  'D-',
  'F'
]
