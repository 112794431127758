import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'

import { Icon } from '@/style-guide'

const StyledResetBtn = styled.button`
  border: 0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: 0;
  background-color: transparent;
`

const ResetButton = ({ onClick }) => (
  <StyledResetBtn onClick={onClick}>
    <Icon color="#fff" name="reload" style={{ marginRight: 4 }} />
    Reset
  </StyledResetBtn>
)

ResetButton.propTypes = {
  onClick: func.isRequired
}

export default ResetButton
