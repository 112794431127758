import React, { useState } from 'react'

import { Button, Grid, Input } from '@/style-guide'

import { percentageToLetterGrade } from '@/helpers/calculators'
import { number } from '@/helpers/validations'
import { useFormCalculator } from '@/hooks/useFormCalculator'
import { CalculatorCard, ResultCard } from '../../components'

const Test = () => {
  const [percentageGrade, setPercentageGrade] = useState('')
  const [letterGrade, setLetterGrade] = useState('')

  const { formValues, formValid, resetValues, changeValue } = useFormCalculator({
    values: {
      numberOfQuestions: '',
      numberOfWrongQuestions: ''
    },
    requiredKeys: ['numberOfQuestions', 'numberOfWrongQuestions'],
    validation: {
      numberOfQuestions: number.positiveNumber,
      numberOfWrongQuestions: number.positiveNumber
    }
  })

  const handleReset = () => {
    setPercentageGrade('')
    resetValues()
  }

  const handleCalculate = () => {
    const { numberOfQuestions, numberOfWrongQuestions } = formValues
    const percentageGrade = 100 - ((numberOfWrongQuestions * 100) / numberOfQuestions).toFixed(2)

    setPercentageGrade(percentageGrade)
    setLetterGrade(percentageToLetterGrade(percentageGrade))
  }

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col style={{ alignItems: 'center' }}>
          <Input label="Percentage" disabled value={`${percentageGrade}%`} />
        </Grid.Col>
        <Grid.Col style={{ alignItems: 'center' }}>
          <Input label="GPA" disabled value={letterGrade} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  return (
    <>
      <CalculatorCard
        title="Test Calculator"
        onReset={handleReset}
        content={
          <Grid>
            <Grid.Row>
              <Grid.Col>
                <Input
                  label="Nº of questions"
                  type="number"
                  value={formValues.numberOfQuestions}
                  onChange={value => changeValue('numberOfQuestions', value)}
                />
              </Grid.Col>
              <Grid.Col>
                <Input
                  label="Nº of wrong questions"
                  type="number"
                  value={formValues.numberOfWrongQuestions}
                  onChange={value => changeValue('numberOfWrongQuestions', value)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        }
        footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
      />

      {percentageGrade !== '' && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default Test
