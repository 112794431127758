import React from 'react'
import { string, number, func } from 'prop-types'

import { GPAS } from '@/helpers/calculators'
import { Dropdown, Label } from '@/style-guide'

const LetterGradeSelector = ({ label, value, onChange }) => (
  <>
    <Label light>{label}</Label>
    <Dropdown options={GPAS} value={value} onChange={onChange} />
  </>
)

LetterGradeSelector.propTypes = {
  label: string,
  value: number,
  onChange: func
}

export default LetterGradeSelector
