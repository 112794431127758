import { toFloat } from '@/helpers/numbers'

export const TABS = {
  PERCENTAGE: 0,
  LETTER: 1,
  GPA: 2
}

export const calculateGrade = grades => {
  const groupValues = grades.map(value => {
    const grade = value.gradeLt ? value.gradeLt.value : value.grade

    return {
      grade: toFloat(grade) * toFloat(value.weight),
      weight: toFloat(value.weight)
    }
  })

  const sumGrades = groupValues.reduce((prev, curr) => {
    return prev + curr.grade
  }, 0)

  const sumWeights = groupValues.reduce((prev, curr) => {
    return prev + curr.weight
  }, 0)

  const result = sumGrades / sumWeights

  return result ? result.toFixed(2) : 0
}
