import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { GpaToLetter } from '@/components/Calculator/Grade'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledGpaToLetterCalculator = styled(GpaToLetter)`
  min-width: unset;
  max-width: unset;
`

const GpaToLetterCalculator = () => (
  <>
    <SEO title="College GPA" />
    <CalculatorStructure
      section="grade"
      headerTitle="grade"
      title={CALCULATORS.grade['gpa-to-letter'].title}
      description={CALCULATORS.grade['gpa-to-letter'].description}
      calculatorNode={<StyledGpaToLetterCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default GpaToLetterCalculator
