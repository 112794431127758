import React from 'react'
import styled from 'styled-components'

import { CalculatorCard } from '../../components'

const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
  }
`

const rows = [
  {
    lg: 'A+',
    pg: '97%-100%',
    gpa: 4.33
  },
  {
    lg: 'A',
    pg: '93%-96%',
    gpa: 4.0
  },
  {
    lg: 'A-',
    pg: '90%-92%',
    gpa: 3.67
  },
  {
    lg: 'B+',
    pg: '87%-89%',
    gpa: 3.33
  },
  {
    lg: 'B',
    pg: '83%-86%',
    gpa: 3
  },
  {
    lg: 'B-',
    pg: '80%-82%',
    gpa: 2.67
  },
  {
    lg: 'C+',
    pg: '77%-79%',
    gpa: 2.33
  },
  {
    lg: 'C',
    pg: '73%-76%',
    gpa: 2.0
  },
  {
    lg: 'C-',
    pg: '70%-72%',
    gpa: 1.67
  },
  {
    lg: 'D+',
    pg: '67%-69%',
    gpa: 1.33
  },
  {
    lg: 'D',
    pg: '63%-66%',
    gpa: 1.0
  },
  {
    lg: 'D-',
    pg: '60%-62%',
    gpa: 0.67
  },
  {
    lg: 'F',
    pg: '0%-59%',
    gpa: 0
  }
]

const LetterToGrade = () => (
  <CalculatorCard
    title="Letter to grade"
    content={
      <Table>
        <thead>
          <tr>
            <th>Letter Grade</th>
            <th>Percentage Grade</th>
            <th>GPA</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(({ lg, pg, gpa }, index) => (
            <tr key={index}>
              <td>{lg}</td>
              <td>{pg}</td>
              <td>{gpa}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    }
  />
)

export default LetterToGrade
