import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Link as LinkComponent } from 'gatsby'
import { string } from 'prop-types'

import { white } from '@/style-guide/colors'

import bgImageLarger from '@/assets/images/bg_header-larger.svg'
import bgImageSmall from '@/assets/images/bg_header-small.svg'

import arrowLeft from '@/assets/icons/arrow-left-white.svg'
import twitter from '@/assets/icons/twitter-white.svg'
import facebook from '@/assets/icons/facebook-white.svg'
import linkedin from '@/assets/icons/linkedin-white.svg'

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 65px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url(${bgImageSmall});
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 430px;
  padding: 40px;

  ${media.greaterThan('medium')`
    height: 369px;
    background-image: url(${bgImageLarger});
    height: 780px;
    padding-top: 65px;
    padding-left: 153px;
    padding-right: 153px;
  `}

  /* for wide monitor */
  ${media.greaterThan('2200px')`
    padding-left: 440px;
    padding-right: 440px;
  `}
`

const Line = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 10px;
  }
`

const Link = styled(LinkComponent)`
  display: flex;
  align-items: center;
  text-decoration: none;

  > * {
    margin-right: 10px;
  }
`

const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${white};
`

const BoldText = styled(Text)`
  font-weight: bold;
  margin-right: 20px;
`

const Img = styled.img``

const Title = styled.span`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  margin: 10px 0px;
  color: ${white};
  text-transform: capitalize;
`

const Header = ({ section, headerTitle }) => (
  <Container>
    <Content>
      <Link to={`/calculator-categories/${section}`}>
        <Img src={arrowLeft} alt="Goback" />
        <Text>All Calculators</Text>
      </Link>
      <Title>{headerTitle}</Title>
      <Line>
        <BoldText>Embed</BoldText>
        <Text>Share Via</Text>
        <Img src={facebook} alt="Facebook" />
        <Img src={twitter} alt="Twitter" />
        <Img src={linkedin} alt="LinkedIn" />
      </Line>
    </Content>
  </Container>
)

Header.propTypes = {
  section: string,
  headerTitle: string
}

export default Header
