export const toFloat = number => (number ? parseFloat(number) : 0)

export const getFixedResult = value => (value ? value.toFixed(2) : 0)

export const formatObjectValuesToNumber = values =>
  Object.keys(values).reduce((prev, curr) => {
    if (typeof values[curr] === 'string') {
      return {
        ...prev,
        [curr]: toFloat(values[curr])
      }
    }

    return {
      ...prev,
      [curr]: values[curr]
    }
  }, {})

export const isValidNumber = value => value !== undefined && value !== null && !Number.isNaN(value)
