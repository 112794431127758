import React from 'react'
import { object, node } from 'prop-types'
import { Card } from '@/style-guide'

const ResultCard = ({ content, footer, style }) => (
  <Card
    style={style}
    header={
      <Card.Header
        light
        title="Result"
        style={{
          background: 'linear-gradient(17.45deg, #4371DD 31.79%, #92E7F2 97.12%)',
          color: '#fff'
        }}
      />
    }
    content={content}
    footer={footer}
  />
)

ResultCard.propTypes = {
  content: node.isRequired,
  footer: node,
  style: object
}

export default ResultCard
