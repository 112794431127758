import { toFloat } from '@/helpers/numbers'

/**
 * @typedef {Object} Grade
 * @property {string} key
 * @property {number} value
 */

/**
 * @typedef {Object} Row
 * @property {string} class
 * @property {Grade} grade
 * @property {string} credit
 */

/**
 * The GPA (Grade Point Average) is calculated as weighted average of the grades.
 *
 * GPA = ((g1 * w1) + (g2 * w2) + (etc)) / sum of credits/hours
 *
 * @param {Row[]} grades
 * @returns {Array} An array with the GPA value and the creditsHours sum
 */
export const calculateGPA = grades => {
  const groupValues = grades.map(value => ({
    ...value,
    grade: toFloat(value.grade.value) * toFloat(value.credit),
    credit: toFloat(value.credit)
  }))

  const sumGrades = groupValues.reduce((prev, curr) => {
    return prev + curr.grade
  }, 0)

  const sumCreditsHours = groupValues.reduce((prev, curr) => {
    return prev + curr.credit
  }, 0)

  const GPA = sumGrades / sumCreditsHours

  return [GPA ? GPA.toFixed(2) : 0, sumCreditsHours]
}
